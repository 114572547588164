<i18n src="@/locales/article85.json" />

<template>
  <div class="fixed-background">
    <header class="columns is-centered">
      <div class="column is-9">
        <h1 class="has-background-primary-light-opacity mt-2">
          {{ $t('h1_title') }}
        </h1>
        <img
          src="@/assets/img/articles/article85/differences-infection-dentaire.jpg"
          alt=""
        >
      </div>
    </header>
    <ImanTestimonialMessage type="is-info">
      <div v-html="$t('message1')" />
    </ImanTestimonialMessage>
    <ImanTestimonialMessage type="is-info">
      <div v-html="$t('message2')" />
    </ImanTestimonialMessage>
    <ImanTestimonialArticle :title="$t('article1_title')">
      <img
        src="@/assets/img/articles/article85/gingivite-traitement.jpg"
        alt=""
      >
      <div class="content">
        <p class="mt-3">
          {{
            $t('article1_p1')
          }}
        </p>
        <h3 class="has-text-primary is-size-5 my-3">
          {{ $t('article1_h3_1') }}
        </h3>
        <div v-html="$t('article1_p2')" />
        <h3 class="has-text-primary is-size-5 my-3">
          {{ $t('article1_h3_2') }}
        </h3>
        <p>
          {{
            $t('article1_p3')
          }}
        </p>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('article2_title')">
      <img
        src="@/assets/img/articles/article85/infection-parodontale.jpg"
        alt=""
      >
      <div class="content">
        <p class="mt-3">
          {{
            $t('article2_p1')
          }}
        </p>
        <p>
          {{
            $t('article2_p2')
          }}
        </p>
        <p>
          {{
            $t('article2_p3')
          }}
        </p>
        <h3 class="has-text-primary is-size-5 my-3">
          {{ $t('article2_h3_1') }}
        </h3>
        <div v-html="$t('article2_p4')" />
        <ImanMoreInfo :path="$t('article2_more_info_path')">
          {{ $t('article2_more_info') }}
        </ImanMoreInfo>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('article3_title')">
      <div class="content">
        <p>
          {{
            $t('article3_p1')
          }}
        </p>
        <p>
          {{
            $t('article3_p2')
          }}
        </p>
        <ImanMoreInfo :path="$t('article3_more_info_path')">
          {{ $t('article3_more_info') }}
        </ImanMoreInfo>
      </div>
    </ImanTestimonialArticle>
  </div>
</template>

<script>
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: "Article85",
  mixins: [metaMixin],
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/miniature_Infections-bucco-dentaires.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/scss/variables';
h1 {
  font-family: 'Comfortaa', cursive;
  color: $white;
  font-size: 1.7rem;
  font-weight: lighter;
  line-height: $title-line-height;
  padding: 0.5rem;
}
</style>
